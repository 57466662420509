import { FC } from 'react'
import Error from './Error'

export default function withError<P>(Component: FC<P>) {
  return ({ errorCode, errorText, ...props }: P & { errorCode: number; errorText?: string }) => {
    if (errorCode) {
      return <Error statusCode={errorCode} errorText={errorText} />
    }

    return <Component {...(props as P)} errorCode={errorCode} />
  }
}
