import { useCallback, useContext, useMemo } from 'react'
import { Address, PracticeProfile, SharedLinkData, SpecialistProfile } from '../types'
import { MediaTypes, mediaTypesWithoutSocials, PracticePlans, ProfileTypes } from '../constants/profile'
import { AppDataContext } from '../providers/AppData'
import { getPracticeSpecialistName } from '../utils/practiceSpecialist'
import useSpecialistWorkingHours from './useSpecialistWorkingHours'
import { useSpecialistPracticesContacts } from './useSpecialistPractices'
import { useKeywordsMapper } from './useKeywordsMapper'
import { getIsProOrExpertProPlan, getIsUnclaimedPlan } from '../utils/getPlanProfile'
import extractFacilitiesTypes from '../utils/extractFacilitiesTypes'
import { useWpaTenant } from './useWpaTenant'

const isMediaIncludeEnabledArticle = (type: MediaTypes, media?: SharedLinkData[]) =>
  !!media?.some((post) => post.type === type && post.enabled)

export const useVisibilityComponents = ({
  specialist,
  practice,
  address
}: {
  specialist?: SpecialistProfile
  practice?: PracticeProfile
  address?: Address
}) => {
  const { language } = useContext(AppDataContext)
  const { hasWorkingHours: specialistHasWoringHours } = useSpecialistWorkingHours(specialist?.practices || [])
  const { facilities, services, parking } = extractFacilitiesTypes(practice?.practiceFacilities || [], language)
  const { practicesWithContactDetails } = useSpecialistPracticesContacts(specialist?.practices || [])
  const { subSpecialties, conditionAndTreatments } = useKeywordsMapper(
    specialist?.keywords || practice?.keywords || [],
    !!practice
  )

  const { isWPA } = useWpaTenant()

  const isUnclaimed = useMemo(() => {
    if (!!practice && !specialist) {
      return getIsUnclaimedPlan(practice, ProfileTypes.PRACTICE)
    }
    if (!!specialist && !practice) {
      return getIsUnclaimedPlan(specialist, ProfileTypes.SPECIALIST)
    }
    return true
  }, [practice, specialist])

  const isBasic = useMemo(() => specialist?.basic || practice?.basic, [specialist, practice])

  const fees = useMemo(() => {
    if (specialist) {
      return (
        !isWPA &&
        !isBasic &&
        !isUnclaimed &&
        !!specialist.consultationFees?.currency &&
        (!!specialist.consultationFees?.followUp || !!specialist.consultationFees?.new)
      )
    }
    return false
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasic, isUnclaimed, specialist?.consultationFees?.currency])

  const insurers = useMemo(
    () => (!!practice?.insurers?.length || !!specialist?.insurers?.length) && !isBasic && !isWPA,
    [isBasic, isWPA, specialist?.insurers, practice?.insurers]
  )

  const about = useMemo(() => {
    const startPermission =
      !!subSpecialties?.length || !!conditionAndTreatments?.length || !!isUnclaimed || !!isBasic || !!fees || !!insurers
    if (specialist) {
      return (
        startPermission ||
        !!specialist.about?.[language] ||
        !!specialist.statistic?.length ||
        !!specialist.registrationBodies?.length ||
        !!specialist.insurers?.length ||
        !!specialist.languages?.length ||
        !!specialist.education?.[language]?.length
      )
    }
    if (practice) {
      return (
        startPermission ||
        !!practice.about?.[language] ||
        !!practice.keywords?.length ||
        !!practice.insurers?.length ||
        !!practice.statistic?.length ||
        !!practice.healthChecks?.length
      )
    }

    return false
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionAndTreatments,
    isUnclaimed,
    isBasic,
    fees,
    insurers,
    specialist?.statistic,
    specialist?.registrationBodies,
    specialist?.insurers,
    specialist?.languages,
    practice?.keywords,
    practice?.insurers,
    practice?.statistic,
    practice?.healthChecks
  ])

  const isProOrExpertPro = useMemo(() => {
    if (specialist) {
      return getIsProOrExpertProPlan(specialist, ProfileTypes.SPECIALIST)
    }
    if (practice) {
      return getIsProOrExpertProPlan(practice, ProfileTypes.PRACTICE)
    }
    return false
  }, [specialist, practice])

  const peerRecommendations = useMemo(
    () => Boolean(specialist && specialist.peerRecommendationsCount),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specialist, specialist?.peerRecommendations]
  )

  const location = useMemo(
    () =>
      !!address ||
      (!!specialist?.practices?.length && specialist?.practices?.some((practice) => !!practice.address?.geolocation)) ||
      !!practice?.address,
    [specialist?.practices, practice, address]
  )

  const reviewsTotal = useMemo(
    () => !!practice?.reviewsTotal || !!specialist?.reviewsTotal,
    [practice?.reviewsTotal, specialist?.reviewsTotal]
  )
  const gallery = useMemo(() => !!practice?.images?.images?.length, [practice?.images?.images])
  const practiceSpecialistName = useMemo(
    () => (practice ? !!getPracticeSpecialistName(practice.specialists?.[0], language) : false),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [practice, practice?.specialists]
  )
  const languages = useMemo(() => !!specialist?.languages?.length, [specialist?.languages])
  // todo: clarify deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mainPracticeName = useMemo(() => !!specialist?.practices?.[0]?.name?.[language], [specialist?.practices])
  const hasWorkingHours = useMemo(
    () => specialistHasWoringHours || !!practice?.workingOpeningHours?.[1]?.length,
    [specialistHasWoringHours, practice?.workingOpeningHours]
  )
  const phone = useMemo(() => !!practice?.phone, [practice?.phone])
  const practiceSpecialists = useMemo(() => !!practice?.specialists?.length, [practice?.specialists])
  const hideGetInTouch = useMemo(
    () =>
      !practicesWithContactDetails?.length &&
      !((!!practice?.phone?.length && !!practice?.email?.length) || !!practice?.plan?.includes(PracticePlans.NO_PLAN)),
    [practicesWithContactDetails, practice?.phone, practice?.email, practice?.plan]
  )
  const hasSpecialistMedia = useMemo(() => !!specialist?.media?.length, [specialist?.media])
  const hasSpecialistMediaEnabled = useMemo(
    () =>
      specialist?.media?.reduce((acc, mediaItem) => {
        if (mediaItem.type !== MediaTypes.PUBLICATION && mediaItem.enabled) {
          return true
        }
        return acc
      }, false),
    [specialist?.media]
  )
  const hasPracticeMedia = useMemo(() => !!practice?.media?.length, [practice?.media])
  const hasPracticeMediaEnabled = useMemo(
    () =>
      practice?.media?.reduce((acc, mediaItem) => {
        if (mediaItem.type !== MediaTypes.PUBLICATION && mediaItem.enabled) {
          return true
        }
        return acc
      }, false),
    [practice?.media]
  )

  const practiceSocials = useMemo(
    () =>
      practice?.media?.filter((post) => !mediaTypesWithoutSocials.includes(post.type as MediaTypes) && post.enabled),
    [practice?.media]
  )

  const specialistSocials = useMemo(
    () =>
      specialist?.media?.filter((post) => !mediaTypesWithoutSocials.includes(post.type as MediaTypes) && post.enabled),
    [specialist?.media]
  )

  const isSpecialistMediaValid = useMemo(
    () => hasSpecialistMedia && hasSpecialistMediaEnabled,
    [hasSpecialistMedia, hasSpecialistMediaEnabled]
  )
  const isPracticeMediaValid = useMemo(
    () => hasPracticeMedia && hasPracticeMediaEnabled,
    [hasPracticeMedia, hasPracticeMediaEnabled]
  )
  const shouldShowMedia = useMemo(
    () =>
      !isWPA &&
      !isUnclaimed &&
      isProOrExpertPro &&
      (isSpecialistMediaValid || isPracticeMediaValid) &&
      (specialistSocials || practiceSocials),
    [
      isWPA,
      isUnclaimed,
      isProOrExpertPro,
      isSpecialistMediaValid,
      isPracticeMediaValid,
      specialistSocials,
      practiceSocials
    ]
  )

  const media = useMemo(() => Boolean(shouldShowMedia), [shouldShowMedia])

  const checkMediaTypeAvailability = useCallback(
    (type: MediaTypes) => {
      return (
        media &&
        (isMediaIncludeEnabledArticle(type, specialist?.media) || isMediaIncludeEnabledArticle(type, practice?.media))
      )
    },
    [media, practice?.media, specialist?.media]
  )

  const videos = useMemo(
    () => checkMediaTypeAvailability(MediaTypes.VIDEO) || checkMediaTypeAvailability(MediaTypes.PROFILE),
    [checkMediaTypeAvailability]
  )

  const articles = useMemo(() => checkMediaTypeAvailability(MediaTypes.ARTICLE), [checkMediaTypeAvailability])

  const introductionVideoAvailable = isProOrExpertPro

  const faqQuestions = {
    faqQuestionReviews: reviewsTotal,
    faqQuestionLanguages: languages,
    faqQuestionWhereLocated: mainPracticeName || practiceSpecialistName,
    faqQuestionsLocation: hasWorkingHours,
    faqQuestionAcceptVisits: !hideGetInTouch,
    faqQuestionPracticePhone: phone,
    faqQuestionConsultants: practiceSpecialists
  }

  const showServices = useMemo(() => !!services.length && !isUnclaimed, [isUnclaimed, services.length])
  const showFacilities = useMemo(() => !!facilities.length && !isUnclaimed, [isUnclaimed, facilities.length])
  const showParking = useMemo(() => !!parking.length && !isUnclaimed, [isUnclaimed, parking.length])
  const showBreadcrumbs = useMemo(() => !isWPA, [isWPA])

  return {
    about,
    articles,
    fees,
    insurers,
    location,
    reviews: true,
    services: showServices,
    facilities: showFacilities,
    parking: showParking,
    peerRecommendations,
    specialists: practiceSpecialists,
    gallery,
    faq: !isBasic && !isUnclaimed && !isWPA && Object.values(faqQuestions).some((value) => value),
    faqQuestions,
    videos,
    media,
    introductionVideoAvailable,
    showBreadcrumbs
  }
}
