import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default, { useState } from 'react';
import tooltipClasses from '../../node_modules/@mui/material/Tooltip/tooltipClasses.esm.js';
import Tooltip from '../../node_modules/@mui/material/Tooltip/Tooltip.esm.js';
export { default as Tooltip } from '../../node_modules/@mui/material/Tooltip/Tooltip.esm.js';
import styledComponent from '../../node_modules/@mui/material/styles/styled.esm.js';

const CustomTooltip = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const [open, setOpen] = useState(false);
    const show = () => setOpen(true);
    const hide = () => setOpen(false);
    return (React__default.createElement(Tooltip, Object.assign({}, props, { onMouseEnter: show, onClick: show, onMouseLeave: hide, open: open }), children));
};
const WhiteTooltip = styledComponent((_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return React__default.createElement(CustomTooltip, Object.assign({}, props, { classes: { popper: className } }));
}, {
    shouldForwardProp: (prop) => prop !== 'isRtl' && prop !== 'hideArrow'
})(({ isRtl, placement, hideArrow }) => ({
    [`& .${tooltipClasses.tooltip}`]: Object.assign({ width: '220px', height: '100px', backgroundColor: '#fff', color: '#2C3341', border: '1px solid #AABDF3', boxShadow: '0px 7px 8px 0px #0203030A', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', borderRadius: '5px', fontSize: '12px', fontWeight: 400, top: 0, padding: '15px 20px', zIndex: 99999, textAlign: 'center' }, (!hideArrow
        ? {
            '&:after, &:before': Object.assign({ zIndex: -1, content: '""', position: 'absolute', pointerEvents: 'none' }, (placement === 'right'
                ? {
                    top: '50%',
                    left: '-10px',
                    transform: 'translateY(-50%)'
                }
                : {
                    top: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                })),
            '&:after': Object.assign({ backgroundColor: '#fff' }, (placement === 'right'
                ? {
                    height: '33px',
                    width: '20px',
                    marginLeft: '10px'
                }
                : {
                    height: '20px',
                    width: '33px',
                    marginTop: '10px'
                })),
            '&:before': Object.assign({ backgroundColor: '#fff', borderRadius: '3px', border: '1px solid #AABDF3', height: '24px', width: '24px' }, (placement === 'right'
                ? {
                    transform: 'rotate(45deg) translateX(-30%) translateY(-40%)'
                }
                : {
                    left: '50%',
                    top: isRtl ? '-22px' : '1px',
                    transform: 'rotate(45deg) translateX(-70%)'
                }))
        }
        : {}))
}));

export { CustomTooltip, WhiteTooltip };
